#ba {
  .ba {
    &__teaser {
      .content-container {
        padding-top: 38px;

        @include mq('large') {
          padding-top: var(--emu-common-spacing-brand-medium);
          padding-bottom: 109px;
        }

        .title-lockup {
          max-width: 500px; //to match design
        }

        .emu-teaser {
          &__pretitle {
            margin-bottom: var(--emu-common-spacing-medium);

            @include mq('large') {
              margin-bottom: 21px;
            }

            @include mq('large', 'max-width') {
              font-size: var(--emu-common-font-sizes-body-regular);
              letter-spacing: 1.12px;
              line-height: var(--emu-common-line-heights-narrow-xl);
            }
          }

          &__title {
            @include mq('large', 'max-width') {
              margin-bottom: var(--emu-common-spacing-large);
            }
          }

          &__disclaimer {
            @include mq('large', 'max-width') {
              margin-top: 31px;
            }
          }
        }
      }
    }

    &__intro-box {
      padding-top: 39px;
      padding-bottom: var(--emu-common-spacing-brand-xs);

      @include mq('large') {
        padding-top: 62px;
        padding-bottom: 78px;
        border-bottom: var(--emu-common-border-width-thin) solid
          var(--emu-common-colors-black);
      }
    }

    &__ba-item {
      @include mq('large') {
        border-top: none;
      }

      .ba-item {
        &__content {
          @include mq('large', 'max-width') {
            padding-top: 44px;
          }

          @include mq('large') {
            border-top: var(--emu-common-border-width-thin) solid
              var(--emu-common-colors-black);
          }

          &--border {
            border: none;
          }
        }

        &__title {
          margin-bottom: var(--emu-common-spacing-large);
        }

        &__cta {
          margin-bottom: var(--emu-common-spacing-medium);
        }

        &__assets {
          > .container:first-child {
            @include mq('large') {
              border-right: var(--emu-common-border-width-thin) solid
                var(--emu-common-colors-white);
            }
          }

          img {
            @include mq('large') {
              height: 599px; //to match design
            }
          }
        }
      }
    }

    &__modal-title-container {
      @include mq('large') {
        padding-top: 55px;
        padding-bottom: 51px;
      }
    }

    &__modal-title {
      @include mq('large') {
        margin-bottom: 5px;
      }
    }

    &__modal-txt {
      @include mq('large') {
        width: 660px; //as per design
        text-align: center; //the alignment is different in the desktop
      }

      p {
        @include mq('large') {
          font-size: var(--emu-common-font-sizes-captions-large);
          line-height: var(--emu-common-line-heights-narrow-jumbo);
        }
      }
    }

    &__licensed-teaser {
      @include mq('large', 'max-width') {
        padding-top: var(--emu-common-spacing-xxs);
        padding-bottom: 1px;
      }

      .emu-teaser__image img {
        padding-left: var(--emu-common-spacing-none);

        @include mq('large', 'max-width') {
          height: 390px; //to match design
        }
      }

      .content-container {
        padding-top: 39px;
        padding-bottom: var(--emu-common-spacing-none);
        flex-wrap: nowrap;
        border-top: var(--emu-common-border-width-thin) solid
          var(--emu-common-colors-black);

        @include mq('large') {
          padding-top: 62px;
          border-top: none;
        }

        .title-lockup {
          max-width: 788px; //to match design
        }

        .emu-teaser {
          &__title {
            margin-bottom: 17px;

            @include mq('large') {
              margin-bottom: 22px;
            }
          }

          &__disclaimer {
            margin-top: var(--emu-common-spacing-brand-small);

            //breakpoint is added to add styles
            @include mq('389px', 'max-width') {
              margin-top: var(--emu-common-spacing-medium);
            }
          }
        }
      }
    }
  }

  .pre-footer {
    .intro-box {
      @include mq('large', 'max-width') {
        padding-bottom: var(--emu-common-spacing-brand-xs);
      }
    }
  }
}
