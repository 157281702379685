#home {
  .home {
    &__intro {
      padding-top: 38px;
      padding-bottom: var(--emu-common-spacing-xxs);
      border-bottom: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-black);

      @include mq('large') {
        padding-top: 62px;
        padding-bottom: 77px;
      }

      .intro-box {
        &__title {
          @include mq('large') {
            max-width: 1064px; // to match with design
          }
        }
      }
    }

    &__fillers {
      .img-txt-grid {
        &__section {
          &--content {
            padding-top: 37px;
            padding-bottom: 83px;

            @include mq('large') {
              padding-top: 65px;
              padding-bottom: var(--emu-common-spacing-brand-medium);
              border-bottom: var(--emu-common-border-width-thin) solid
                var(--emu-common-colors-black);
            }
          }
        }

        &__title {
          margin-bottom: 18px;

          @include mq('large') {
            max-width: 586px; //to match with design
            margin-bottom: 15px;
          }
        }

        &__text {
          margin-bottom: 15px;

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-medium);
          }
        }
      }
    }

    &__real-results {
      padding-bottom: var(--emu-common-spacing-brand-medium);
      padding-top: 37px;

      @include mq('large') {
        padding-top: 61px;
        padding-bottom: 102px;
      }

      .intro-box {
        &__title {
          margin-bottom: 17px;

          @include mq('large') {
            margin-bottom: 5px;
          }
        }

        &__cta {
          &-container {
            margin-top: 15px;

            @include mq('large') {
              margin-top: 18px;
            }
          }
        }
      }
    }

    &__hiw {
      padding-top: 37px;
      padding-bottom: var(--emu-common-spacing-brand-xs);
      border-bottom: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-black);

      @include mq('large') {
        padding-top: 61px;
        padding-bottom: 77px;
      }
    }

    &__img-txt-grid {
      .img-txt-grid {
        &__section {
          &--content {
            padding-top: 37px;

            @include mq('large') {
              padding-top: 66px;
              padding-bottom: 55px;
            }
          }
        }

        &__title {
          margin-bottom: 19px;

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-medium);
          }
        }

        &__text {
          sup {
            top: 2px;
          }
        }

        &__disc {
          margin-top: 31px;

          @include mq('large') {
            margin-top: var(--emu-common-spacing-none);
          }
        }
      }
    }
  }
}
