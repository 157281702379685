#how-it-works {
  .hiw {
    &__hero-teaser {
      .content-container {
        padding-top: 30px;
        padding-bottom: var(--emu-common-spacing-brand-small);

        @include mq('large') {
          padding-top: var(--emu-common-spacing-brand-medium);
          padding-bottom: 46px;
        }
      }
    }

    &__tabs {
      margin-top: -17px; // to match figma
      position: relative;

      @include mq('large') {
        margin-top: var(--emu-common-spacing-none);
      }

      .aaaem-tabs {
        &__tab {
          a {
            padding-bottom: 18px;

            @include mq('large') {
              padding-top: var(--emu-common-spacing-xxs);
              padding-bottom: 34px;
            }

            span {
              letter-spacing: normal;
            }
          }
        }

        &__tablist {
          @include mq('large') {
            padding-top: 48px;
          }
        }
      }
    }

    &__img-txt-grid {
      .img-txt-grid {
        &__content {
          padding-top: var(--emu-common-spacing-brand-xs);
          padding-bottom: var(--emu-common-spacing-large);
          padding-left: var(--emu-common-spacing-medium);
          padding-right: var(--emu-common-spacing-medium);

          @include mq('large') {
            padding-top: var(--emu-common-spacing-brand-medium);
            padding-bottom: var(--emu-common-spacing-brand-medium);
            padding-left: var(--emu-common-spacing-brand-medium);
            padding-right: var(--emu-common-spacing-brand-medium);
          }

          &--top {
            @include mq('large') {
              padding-bottom: 48px;
            }
          }

          &--bottom {
            padding-top: 39px;
          }
        }

        &__count {
          margin-bottom: 31px;

          @include mq('large', 'max-width') {
            width: 24px; // to match design
            height: 24px; // to match design
          }

          @include mq('large') {
            margin-bottom: 33px;
          }
        }

        &__title-container {
          margin-bottom: 17px;

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-medium);
          }
        }

        &-cta {
          margin-bottom: 39px;
        }
      }
    }

    &__consultation-img-txt-grid {
      .img-txt-grid {
        &__content {
          &--bottom {
            @include mq('large') {
              padding-top: 53px;
            }
          }
        }

        &__disc {
          &-title {
            margin-bottom: 7px;

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-small);
            }
          }

          &-cta {
            margin-bottom: 39px;

            @include mq('large') {
              margin-bottom: 23px;
            }
          }

          &-text {
            margin-bottom: var(--emu-common-spacing-brand-small);

            @include mq('large') {
              margin-bottom: 25px;
            }
          }
        }
      }
    }

    &__treatment-img-txt-grid {
      .img-txt-grid {
        &__content {
          &--bottom {
            padding-bottom: var(--emu-common-spacing-brand-xs);

            @include mq('large') {
              padding-top: 53px;
              padding-bottom: var(--emu-common-spacing-brand-small);
            }
          }
        }

        &__disc {
          &-title {
            margin-bottom: var(--emu-common-spacing-small);
          }
        }
      }
    }

    &__results-img-txt-grid {
      .img-txt-grid {
        &__content {
          &--bottom {
            @include mq('large') {
              padding-top: 53px;
            }
          }
        }

        &__text {
          p {
            font-size: var(--emu-common-font-sizes-body-medium);
            line-height: var(--emu-common-line-heights-narrow-xxxl);
          }
        }

        &__disc {
          &-text {
            margin-bottom: var(--emu-common-spacing-brand-small);

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-brand-xs);
            }
          }

          &-title {
            margin-bottom: 7px;
          }
        }
      }
    }

    &__intro {
      padding-top: 38px;
      padding-right: var(--emu-common-spacing-medium);
      padding-left: var(--emu-common-spacing-medium);

      @include mq('large') {
        padding-top: 61px;
        padding-right: var(--emu-common-spacing-brand-medium);
        padding-left: var(--emu-common-spacing-brand-medium);
      }

      .intro-box__title {
        margin-bottom: var(--emu-common-spacing-none);
      }
    }

    &__carousel {
      padding-bottom: var(--emu-common-spacing-none);
      padding-top: 105px;

      @include mq('large') {
        padding-top: 86px;
      }

      .tns-nav {
        top: -76px;

        @include mq('large') {
          top: -58px;
        }
      }

      .emu-carousel__action {
        @include mq('large') {
          top: 295px;
        }

        &-prev {
          left: 52px;
        }

        &-next {
          right: 52px;
        }
      }

      .brand-card {
        border-right: var(--emu-common-border-width-thin) solid
          var(--emu-common-colors-black);
        width: 100%;

        &__content {
          padding-top: 31px;
          padding-right: var(--emu-common-spacing-medium);
          padding-bottom: var(--emu-common-spacing-large);
          padding-left: var(--emu-common-spacing-medium);
          min-height: 501px; // as per figma

          @include mq('large') {
            padding-top: 51px;
            padding-right: var(--emu-common-spacing-brand-medium);
            padding-bottom: 57px;
            padding-left: var(--emu-common-spacing-brand-medium);
            min-height: 601px; // as per figma
          }
        }

        &__title {
          @include mq('large') {
            margin-bottom: 7px;
          }
        }
      }
    }

    &__faq {
      .faq {
        &__section {
          &--left {
            padding-top: 63px;
            padding-right: var(--emu-common-spacing-medium);
            padding-bottom: var(--emu-common-spacing-brand-small);
            padding-left: var(--emu-common-spacing-medium);

            @include mq('large') {
              padding-top: 60px;
              padding-right: var(--emu-common-spacing-brand-medium);
              padding-bottom: var(--emu-common-spacing-brand-small);
              padding-left: var(--emu-common-spacing-brand-medium);
            }
          }

          &--right {
            @include mq('large') {
              padding-top: var(--emu-common-spacing-brand-medium);
            }
          }

          .plus-minus-icon {
            .aaaem-accordion__icon {
              @include mq('large') {
                margin-left: 50px;
              }
            }
          }
        }

        &__description {
          margin-bottom: var(--emu-common-spacing-brand-xs);
        }

        &__filter-cta-container {
          padding-top: var(--emu-common-spacing-small);
          margin-top: var(--emu-common-spacing-medium);
          margin-left: -16px;
          margin-right: -16px;

          @include mq('large') {
            margin-top: var(--emu-common-spacing-brand-xs);
          }
        }
      }
    }

    &__articles-intro {
      padding-top: 50px;
      padding-right: var(--emu-common-spacing-medium);
      padding-bottom: 48px;
      padding-left: var(--emu-common-spacing-medium);

      @include mq('large') {
        padding-top: 61px;
        padding-right: var(--emu-common-spacing-brand-medium);
        padding-bottom: 87px;
        padding-left: var(--emu-common-spacing-brand-medium);
      }

      .intro-box__title {
        margin-bottom: var(--emu-common-spacing-none);
      }
    }

    &__articles-carousel {
      padding-top: var(--emu-common-spacing-none);
      padding-bottom: var(--emu-common-spacing-none);

      .emu-carousel {
        &__content {
          display: flex;

          > .emu-carousel__item {
            flex: 1;

            > .carouselslide {
              height: 100%;

              > .emu-carousel__item-container {
                height: 100%;

                > .container {
                  height: 100%;

                  > .cmp-container {
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }

      .brand-card {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-top: none;

        > .container {
          &:last-child {
            flex: 1;
            border-right: var(--emu-common-border-width-thin) solid
              var(--emu-common-colors-black);
          }
        }

        &__asset {
          img {
            @include mq('large', 'max-width') {
              max-height: 189px; // to match figma
              object-fit: cover;
            }
          }
        }

        &__content {
          min-height: 311px; // as per figma
          border-top: none;
          padding-top: var(--emu-common-spacing-large);
          padding-right: var(--emu-common-spacing-medium);
          padding-bottom: var(--emu-common-spacing-large);
          padding-left: var(--emu-common-spacing-medium);

          @include mq('large') {
            min-height: 330px; // as per figma
            padding-top: 41px;
            padding-right: var(--emu-common-spacing-brand-medium);
            padding-bottom: var(--emu-common-spacing-brand-small);
            padding-left: var(--emu-common-spacing-brand-medium);
          }
        }

        &__subtitle {
          margin-bottom: 10px;

          @include mq('389px', 'max-width') {
            padding-right: 18px;
          }

          .cmp-title__text {
            line-height: var(--emu-common-line-heights-custom-regular);
            letter-spacing: -0.1px;
          }
        }
      }
    }

    &__journey-teaser {
      .emu-teaser__description {
        @include mq('x-large') {
          max-width: 360px; // to match figma
        }
      }
    }
  }

  .pre-footer {
    .intro-box {
      padding-top: 37px;

      @include mq('large') {
        padding-top: 71px;
        padding-bottom: 85px;
      }
    }

    .img-txt-grid {
      &__title {
        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-medium);
        }
      }

      &__text {
        @include mq('large') {
          margin-bottom: 25px;
        }
      }
    }
  }
}
