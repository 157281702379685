[data-component='container-isi-v2'] {
  .aaaem-isi {
    &-banner {
      @include max-container;
      position: relative;

      @include mq('large') {
        justify-content: flex-start;
        align-items: flex-start;
      }

      button {
        padding: var(--emu-common-spacing-none);

        span {
          font-size: 0;
        }

        svg {
          margin-right: var(--emu-common-spacing-none);
        }
      }

      p {
        font-size: var(--emu-common-font-sizes-captions-large);
        line-height: var(--emu-common-line-heights-narrow-jumbo);
        margin-top: var(--emu-common-spacing-none);
      }
    }

    &-container {
      &__banner-content {
        color: var(--emu-common-colors-brand-black-100);

        @include mq('large') {
          width: calc(
            50% - 2px
          ); // intentional calculation to match the design closer
          padding-right: var(--emu-common-spacing-brand-small);
        }

        p {
          @include mq('large') {
            font-weight: var(--emu-common-font-weight-600);
          }

          b {
            display: block;
            margin-bottom: 18px;
            margin-right: 15px;
  
            @include mq('large') {
              display: inline;
              margin-right: var(--emu-common-spacing-none);
            }
          }
        }

        &[data-ref='banner-content-on-open'] {
          display: none;

          @include mq('large') {
            display: block;
            width: 50%;
            padding-right: var(--emu-common-spacing-brand-xs);
          }

          p {
            font-weight: var(--emu-semantic-font-weight-regular);
          }
        }
      }

      &__button-wrapper {
        position: absolute;
        right: 20px;
        top: 16px;

        @include mq('large') {
          right: 60px;
          top: 15px;
        }
      }

      &__isi-content {
        @include max-container;
      }
    }
  }

  .juv-isi__inner {
    &-top-container {
      display: flex;
      flex-direction: column;
      margin-bottom: var(--emu-common-spacing-brand-xs);

      @include mq('large') {
        flex-direction: row;
        margin-bottom: 46px;
      }

      > .container:first-child {
        margin-bottom: 12px;

        @include mq('large') {
          flex-shrink: 0;
          width: 450px; // As per figma
          margin-bottom: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-medium);
        }
      }
    }

    &-bottom-container {
      padding-top: var(--emu-common-spacing-brand-xs);

      @include mq('large') {
        padding-top: 46px;
      }
    }

    &-bottom-wrapper {
      display: flex;
      flex-direction: column-reverse;

      @include mq('large') {
        flex-direction: row;
      }

      > .container {
        @include mq('large') {
          width: 50%;
        }

        &:first-child {
          @include mq('large') {
            padding-right: 12px;
          }
        }

        &:last-child {
          @include mq('large') {
            padding-left: 12px;
          }
        }
      }
    }
  }

  .isi-content {
    &__title .cmp-title__text {
      font-size: 28px;
      line-height: 128%;
      letter-spacing: -0.6px;
      font-weight: 500; // no variable for this
      margin-bottom: var(--emu-common-spacing-brand-xs);

      @include mq('large') {
        font-size: 36px;
        line-height: 122%;
        letter-spacing: -1.2px;
        margin-bottom: 46px;
      }
    }

    &__txt {
      &.isi-content__txt--question {
        margin-top: 52px;

        h2,
        h3,
        h4,
        h5 {
          font-weight: var(--emu-common-font-weight-600);
        }
      }

      &.isi-content__txt--no-mt {
        margin-top: var(--emu-common-spacing-none);
      }

      &.isi-content__txt--disclaimer {
        margin-top: 25px;

        p + p {
          margin-top: 22px;

          @include mq('large') {
            margin-top: var(--emu-common-spacing-brand-xs);
          }
        }
      }

      h2,
      h3,
      h4,
      h5 {
        font-family: inherit;
        letter-spacing: -0.1px;
      }

      p,
      h2,
      h3,
      h4,
      h5 {
        font-size: var(--emu-common-font-sizes-body-regular);
        line-height: 162%;

        & + p {
          margin-top: 26px;
        }

        b {
          line-height: 26px;

          .emphasis {
            line-height: var(--emu-common-line-heights-narrow-jumbo);
            display: block;
          }

          a {
            display: inline-block;
            text-decoration: inherit;
          }
        }
      }

      ul {
        margin-top: 27px;
        margin-bottom: 26px;
        padding-left: var(--emu-common-spacing-brand-xs);

        @include mq('large') {
          padding-left: var(--emu-common-spacing-brand-xs);
        }

        li {
          margin-top: var(--emu-common-spacing-none);
          line-height: 162%;
          list-style: none;
          position: relative;

          @include mq('large') {
            font-size: var(--emu-common-font-sizes-body-regular);
          }

          &::after {
            content: '';
            position: absolute;
            left: -14px;
            top: 11px;
            background-color: currentColor;
            width: 4px;
            height: 4px;
            border-radius: 50%;
          }

          & + li {
            margin-top: 26.5px;

            @include mq('large') {
              margin-top: 26px;
            }
          }
        }
      }
    }
  }
}